import React, { useState, useEffect } from 'react';
import MobileMenu from './MobileMenu';
import { NavLink } from 'react-router-dom';
// import { FaXTwitter } from "react-icons/fa6";
import Phone from '../components/ServiceDetails/PhoneNumber';
import StyledButton from '../components/ServiceDetails/StyledButton';
import FreeQuoteHeader from '../components/ServiceDetails/FreeQuoteHeader';
import NewSignUpBtn from '../components/ServiceDetails/NewSignUpBtn';
import NewLoginBtn from '../components/ServiceDetails/NewLoginBtn';

export default function Header1() {
  const [isToggled, setToggled] = useState(false);
  const [isSelect, setSelect] = useState("Eng");
  const [isMobileMenu, setMobileMenu] = useState(false);
  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 100);
    });
  }, [scroll]);

  const toggleTrueFalse = () => setToggled(!isToggled);
  const MobileMenuTrueFalse = () => setMobileMenu(!isMobileMenu);

  return (
    <>
      <header className="header">
        <div className="header__top">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-6 col-md-12 p-0">
                <ul className="header__info ">

                  <li>
                    <Phone />
                  </li>
                  <li className='theme'><NavLink to="mailto:info@silkdigitizing.com" aria-label='Email Option'><i className=" fal fa-envelope-open"></i> info@silkdigitizing.com</NavLink></li>
                </ul>
              </div>
              <div className="col-lg-6 col-md-12 my-auto p-0">
                {/* <div className="social__links">
                  <NavLink to="https://www.facebook.com/silkdigitize/" target='__blank' aria-label='Facebook Page'><i className="fab fa-facebook-f"></i></NavLink>
                  <NavLink to="https://twitter.com/SilkDigitizing" target='__blank' aria-label='X Account'><FaXTwitter /></NavLink>
                  <NavLink to="https://www.pinterest.com/silkdigitize/" target='__blank' aria-label='Pinterest Account'><i className="fab fa-pinterest-p"></i></NavLink>
                  <NavLink to="https://www.linkedin.com/company/silk-digitizing" target='__blank' aria-label='LinkedIn Page'><i className="fab fa-linkedin-in"></i></NavLink>
                  <NavLink to="https://www.alignable.com/dallas-tx/silk-digitizing" target='__blank' aria-label='Alignable Page'><i className="fa fa-font"></i></NavLink>
                </div> */}
                <div className='qba'>
                  <div style={{ display: 'flex' }}>
                  <NewLoginBtn/>
                  <NewSignUpBtn />
                  </div>
                  <div>
                  <FreeQuoteHeader />
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={`header__bottom ${scroll ? "sticky-header animated fadeInDown" : ""}`}>
          <div className="container-fluid">
            <div className="row">
              <div className="col-xl-10 col-lg-12">
                <div className="navarea">
                  <NavLink to="/" className="site-logo logowidth" aria-label='Company Logo'>
                    <img src="/images/logo/silk-digitizing-logo-128x106.webp" alt="LOGO" width={128} height={106} style={{ width: '100%', height: '100%', objectFit: 'contain', maxWidth: '100%' }} />
                  </NavLink>

                  <div className="mainmenu d-none d-lg-block">
                    <nav id="mobile-menu">
                      <ul>
                        <li><NavLink to="/">Home</NavLink></li>
                        <li className="menu_has_children"><NavLink to="/services">Services</NavLink>
                          <ul className="sub-menu">
                            <li className='second-parent'>
                              <NavLink to="/services/embroidery-digitizing" className='a'>Embroidery Digitizing</NavLink>
                              {/* <ul className="sub-menu1">
                                <li><NavLink to="/services/embroidery-digitizing/flat-embroidery-digitizing">Flat Embroidery Digitizing</NavLink></li>
                                <li><NavLink to="/services/embroidery-digitizing/applique-embroidery-digitizing">Applique Embroidery Digitizing</NavLink></li>
                                <li><NavLink to="/services/embroidery-digitizing/3D-puff-embroidery-digitizing">3D Puff Embroidery Digitizing</NavLink></li>
                                <li><NavLink to="/services/embroidery-digitizing/chenille-embroidery-digitizing">Chenille Embroidery Digitizing</NavLink></li>
                                <li><NavLink to="/services/embroidery-digitizing/sequin-embroidery-digitizing">Sequin Embroidery Digitizing</NavLink></li>
                              </ul> */}
                            </li>
                            <li><NavLink to="/services/vector-conversion">Vector Conversion</NavLink></li>
                            {/* <li className='second-parent'><NavLink to="/services/graphic-designing">Graphic Designing</NavLink>
                              <ul className="sub-menu1">
                                <li><NavLink to="/services/graphic-designing/logo-designing">Logo Designing</NavLink></li>
                                <li><NavLink to="/services/graphic-designing/photo-editing">Photo Editing</NavLink></li>
                              </ul>
                            </li>
                            <li><NavLink to="/services/line-art">Line Art</NavLink></li> */}
                          </ul>
                        </li>
                        <li><NavLink to="/custom-patches">Custom Patches</NavLink></li>
                        <li><NavLink to="/portfolio">Portfolio</NavLink></li>
                        <li><NavLink to="/pricing">Pricing</NavLink></li>
                        <li><NavLink to="/contact#contact">Contact</NavLink></li>
                        <li><NavLink to="/about">About</NavLink></li>
                        {/* <li><NavLink to="#">Blogs</NavLink></li> */}
                      </ul>
                    </nav>
                  </div>

                  <div className="mobile-menu mean-container d-block d-lg-none ">
                    <div className="mean-bar">
                      <NavLink onClick={MobileMenuTrueFalse} to="#nav" className={isMobileMenu ? "meanmenu-reveal d-none" : "meanmenu-reveal"} aria-label='Open Menu'>
                        <span></span>
                        <span></span>
                        <span></span>
                      </NavLink>
                      <NavLink onClick={MobileMenuTrueFalse} to="#nav" className={isMobileMenu ? "meanmenu-reveal" : "meanmenu-reveal d-none"} style={{ right: "0px", left: "auto", textAlign: "center", textIndent: "0px", fontSize: "18px" }} aria-label='Close Menu'>
                        X
                      </NavLink>
                    </div>
                    {isMobileMenu && <MobileMenu />}
                  </div>
                </div>
              </div>

              <div className="col-xl-2 col-lg-2 my-auto mycss">
                <div className="lang-quote">
                  <NavLink target='_blank' to="https://www.silkdigitizing.com/cp/login.asp" className="quote-btn p-shade-bg dark-purple">Login</NavLink>
                </div>
                <div className="lang-quote">
                  <NavLink target='_blank' to="https://www.silkdigitizing.com/cp/register.asp" className="quote-btn p-shade-bg dark-purple">Register</NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}
