import React from 'react';
import { Link } from 'react-router-dom';



const styles = {
  prominentButton: {
        padding: '7px 17px',
        fontSize: '16px',
        fontWeight: 'bold',
        color: '#ffffff',
        backgroundColor: '#3e3e7a',
        backgroundImage: 'linear-gradient(135deg, #3e3e7a 0%, #5a5a99 100%)', // Gradient in shades of #3e3e7a
        border: 'none',
        // borderRadius: '12px',
        borderRadius: '3px',
        cursor: 'pointer',
        transition: 'transform 0.2s, background-color 0.2s',
        display: 'inline-block',
  },
  prominentButtonHover: {
    backgroundImage: 'linear-gradient(135deg, #5a5a99 0%, #3e3e7a 100%)', // Reversed gradient for hover effect
  },
};



// Attach the hover effect to the button
const NewLoginBtn = () => (
    <Link target='_blank' className='mobile laptop' to="https://www.silkdigitizing.com/cp/login.asp"> <button
    style={styles.prominentButton}
    
  >
    LOGIN
    
  </button></Link>
);

export default NewLoginBtn;
