import React from 'react';
import { FaFacebookF, FaPinterestP, FaLinkedinIn, FaFont, FaMapMarkerAlt, FaPhoneAlt, FaEnvelope } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import WhatsAppButton from '../components/WhatsAppBtn';
import { FaXTwitter } from 'react-icons/fa6'

export default function Footer() {
    return (
        <>
            <WhatsAppButton />
            {/* <!-- footer start --> */}
            <footer className="footer footer__2 pt-70">
                <div className="container">
                    <div className="row mt-none-50 justify-content-center">
                        {/* Column 1: Logo and Social Links */}
                        <div className="col-xl-3 col-lg-3 mt-50">
                            <Link to="/" className="footer__logo" aria-label='Company Logo'>
                                <img loading='lazy' src="/images/logo/logo.webp" alt="" width={196} height={162} style={{ maxWidth: '100%' }} />
                            </Link>
                            <div className="social__links social__links--2 mt-20" style={{ justifyContent: 'flex-start' }}>
                                <Link to="https://www.facebook.com/silkdigitize/" target="_blank" aria-label='Facebook Page'><FaFacebookF /></Link>
                                <Link to="https://twitter.com/SilkDigitizing" target="_blank" aria-label='X Account'><FaXTwitter /></Link>
                                <Link to="https://www.pinterest.com/silkdigitize/" target="_blank" aria-label='Pinterest Account'><FaPinterestP /></Link>
                                <Link to="https://www.linkedin.com/company/silk-digitizing" target="_blank" aria-label='LinkedIn Page'><FaLinkedinIn /></Link>
                                <Link to="https://www.alignable.com/dallas-tx/silk-digitizing" target="_blank" aria-label='Alignable Page'><FaFont /></Link>
                            </div>
                        </div>

                        {/* Column 2: Useful Links */}
                        <div className="col-xl-3 col-lg-3 mt-50">
                            <div className="footer-widget footer-widget__2">
                                <h4 className="widget-title">Useful Links</h4>
                                <ul>
                                    <li><Link to="/"><i className="fa fa-angle-right"></i>Home</Link></li>
                                    <li><Link to="/about"><i className="fa fa-angle-right"></i>About</Link></li>
                                    <li><Link to="/faq"><i className="fa fa-angle-right"></i>FAQ</Link></li>
                                    {/* <li><Link to="#"><i className="fa fa-angle-right"></i>Blogs</Link></li> */}
                                    <li><Link to="/privacy-policy"><i className="fa fa-angle-right"></i>Privacy Policy</Link></li>
                                    <li><Link to="/refund-policy"><i className="fa fa-angle-right"></i>Refund Policy</Link></li>
                                </ul>
                            </div>
                        </div>

                        {/* Column 3: Contact Us */}
                        <div className="col-xl-3 col-lg-3 mt-50">
                            
                            <div className="footer-widget footer-widget__2">
                                <h4 className="widget-title">Order Now</h4>
                                <ul>
                                    <li><Link to="/services/embroidery-digitizing"><i className="fa fa-angle-right"></i>Embroidery Digitizing</Link></li>
                                    <li><Link to="/services/vector-conversion"><i className="fa fa-angle-right"></i>Vector Conversion</Link></li>
                                    <li><Link to="/custom-patches"><i className="fa fa-angle-right"></i>Custom Patches</Link></li>
                                    <li><Link target="_blank" to="https://www.silkdigitizing.com/cp/login.asp"><i className="fa fa-angle-right"></i>Login</Link></li>
                                    <li><Link target="_blank" to="https://www.silkdigitizing.com/cp/register.asp"><i className="fa fa-angle-right"></i>Register</Link></li>
                                </ul>
                            </div>
                        </div>

                        {/* Column 4: Order Now */}
                        <div className="col-xl-3 col-lg-3 mt-50">
                        <div className="footer-widget footer-widget__2">
                                <h4 className="widget-title contact-hover">Contact Us</h4>
                                <ul>
                                    <li>
                                        
                                        <FaMapMarkerAlt style={{ color: 'white', marginRight: '10px' }} />
                                        2918 Avenue I #1003<br />
                                        Brooklyn, NY 11210<br />
                                        United States
                                    </li>
                                    <li>
                                        <Link to="tel:+1 929-279-8016" >
                                        <FaPhoneAlt style={{ color: 'white', marginRight: '10px' }} />
                                        (929) 279-8016
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="mailto:info@silkdigitizing.com">
                                        <FaEnvelope style={{ color: 'white', marginRight: '10px' }} />
                                        info@silkdigitizing.com
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        {/* Newsletter Section */}
                        <div className="col-xl-12 col-lg-12 mt-0" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <div className="footer-widget footer-widget__2">
                                <div className="newslater">
                                    <h4 className="newslater__title">Subscribe to our Newsletter</h4>
                                    <div className="newslater__form">
                                        <form method='POST' action="https://www.silkdigitizing.com/cp/subscribe_action.asp">
                                            <input type="email" name="email" id="email" placeholder="Enter Email" />
                                            <button type="submit" aria-label='Submission Button'><i className="far fa-paper-plane"></i></button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Footer Bottom */}
                <div className="footer__bottom footer__bottom--2 mt-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 my-auto">
                                <div className="copyright-text">
                                    <p className='mb-0'>Copyright &copy; 2015 - 2024 <Link to="https://silkdigitizing.com">Silk Digitizing</Link> All Rights Reserved.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            {/* <!-- footer end --> */}
        </>
    );
}
